
import { Component, Prop, toNative, Vue, Watch } from 'vue-facing-decorator';
import { Datetime } from 'vue-datetime3';
import 'vue-datetime3/dist/style.css';
import moment from 'moment';

@Component({
  components: {
    Datetime,
  },
})
class Datepicker extends Vue {
  @Prop({ default: () => new Date() }) private date!: Date;
  @Prop({ default: false }) private disable!: boolean;
  @Prop({ default: false }) private fullDayTimeFormat!: boolean;
  @Prop({ default: false }) private dateOnly!: boolean;
  @Prop({ default: '' }) private format!: string;
  @Prop({ default: '' }) private idValue!: string;
  @Prop({ default: '' }) private mindatetime!: string;
  @Prop({ default: '' }) private maxdatetime!: string;

  private iconurl: string = require('@/assets/Images/Calender.svg');
  private localmindatetime = '';
  private localmaxdatetime = '';
  private bindedDate = '';
  private datePickerColor = '#6e6e6e';

  mounted() {
    this.localmindatetime = this.mindatetime;
    this.localmaxdatetime = this.maxdatetime;
    this.updateDate(this.date);
  }

  private updateDate(date: Date) {
    this.bindedDate = date ? moment(date).utc().format() : '';
  }

  private GetValue() {
    return this.bindedDate ? moment(this.bindedDate).utc().format(this.GetDateFormat()) : '';
  }

  private GetDateFormat() {
    if (this.dateOnly) {
      return 'MMM D, YYYY';
    } else {
      return this.fullDayTimeFormat ? 'MMM D, YYYY, HH:mm' : 'MMM D, YYYY, hh:mm A';
    }
  }

  private SetDateTime() {
    if (this.bindedDate !== '') {
      this.$emit('selectedUTCDate', this.bindedDate);
      const selectedate: Date = new Date(this.bindedDate);
      selectedate.setSeconds(0);
      this.$emit('selectedDate', moment(selectedate).format());
    }
  }

  private openPicker() {
    const link = document.getElementById(this.idValue);
    if (link) {
      link.click();
    }
  }

  get isDisabled() {
    return this.disable;
  }

  get useFullDayTimeFormat() {
    return !this.fullDayTimeFormat;
  }

  get dateType() {
    return this.dateOnly ? 'date' : 'datetime';
  }

  @Watch('mindatetime')
  private onmindatetimeChanged(val: string) {
    this.localmindatetime = val;
  }

  @Watch('date')
  private OnDateChange(val: Date) {
    this.updateDate(val);
  }
}
export default toNative(Datepicker);
