
import { Component, Vue, toNative, Prop} from 'vue-facing-decorator';
@Component({})
class ValidationSummary extends Vue {
  @Prop() private errorFields?: string[];
  @Prop() private validationSummaryHeading?: string;
  private localValidationSummaryHeading = '';
  private mounted() {
    this.localValidationSummaryHeading = this.validationSummaryHeading === null || this.validationSummaryHeading === undefined ? this.$t('Warning.Message').toString() : this.validationSummaryHeading;
  }
  private Close() {
    this.$emit('close-click', false);
  }
}
export default toNative(ValidationSummary);
